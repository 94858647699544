export default {
  SET_LIST_ACTUAL(state, payload) {
    state.actualReserveTransfers.data = payload.data;
    state.actualReserveTransfers.total = payload.total;
    state.actualReserveTransfers.count = payload.count;
  },
  SET_LIST_ARCHIVE(state, payload) {
    state.archiveReserveTransfers.data = payload.data;
    state.archiveReserveTransfers.total = payload.total;
    state.archiveReserveTransfers.count = payload.count;
  },
  SET_REPORT(state, payload) {
    state.report = payload;
  },
};
